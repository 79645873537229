$COMPANY:actusite;
//Bootstrap default var and componant
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

//Bootstrap général
@import "~bootstrap/scss/bootstrap";

//FONT AWESOME
@import "~font-awesome/scss/font-awesome";

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spectral&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body{
    font-family: Lato;
}

.container-alert{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    z-index: 1002;
}
.container-alert .alert{
    z-index: 1002;
}

.container-alert .alert-error{
    background-color: #dc1818;
    border-color:  #dc1818;
}

/* CARD ACTUALITE */
.design-pico-contact{
    border:10px solid white;
    line-height: 70px;
    border-radius: 50%;
    display:inline-block;
    width: 90px;
    height: 90px;
    font-size: 45px;
    z-index: 5000;
}
.input-border-bottom{
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;

}
.input-border-bottom:focus{
    box-shadow: none ! important;
}
.card-footer {
    background-color: rgba(0, 0, 0, 0);
    border-top: 1px solid rgba(0, 0, 0, 0);
}
.wo{
    display: inline;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}
.carrousel-actualite-date{
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    list-style-type: none;
    margin: 10px;
}
.actualites-container-titre{
    margin-right: -75%;
    position: relative;
    margin-top: 10px;
    padding: 0.5rem 1.2rem 0.5rem 0.5rem;
    z-index: 1;
    height: auto;
    line-height: normal;
    height: auto;
    max-height: 180px;
    overflow: hidden;
}
.carrousel-actualite-btn>a{
    position: absolute;
    margin: 15px;
    bottom: 0px;
}
.carousel .carousel-indicators {
    bottom: -35px;
}
.long-ligne-3 {
    flex: 1;
    min-width: 0;/* Important for long words! */
}
.long-ligne-3>span, .long-ligne-3>span>p {
    display: inline;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;/* Important for long words! */
}
.lien-article-title{
    text-decoration: none !important;
}
/* PAGE PROFIL */
.margin-15 {
    margin: 15px;
}
/* COOKIE CONSENT */
.alert-consent{
    margin-bottom: 0 !important;
}

footer {
    padding-top: 30px;
}
b, strong {
    font-weight: bold !important;
}

.container-page-error{
    display: block;
    position: relative;
    width: 100%;
    height: 100vh;
}
.container-error{
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
}




